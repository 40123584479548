import * as S from './MediumScreenFooter.styles'
import { mediumBottomLinks } from '../data'
import ChatSection from '../ChatSection/ChatSection'
import React, { useMemo } from 'react'
import useScreenSize from '../../../hooks/useScreenSize'
import { LANGUAGES } from '../../../locale/Translation.consts'
import { getMediumFooterLinks } from './MediumScreenFooter.utils'
import { useTranslation } from 'react-i18next'
import { useLanguage } from 'services/LangProvider'
import { useKeys } from 'services/KeysProvider'
import { useExperimentManager } from 'services/ExperimentManager/ExperimentManager'
import useLanguageDialog from '../useLanguageDialog'

export default function MediumScreenFooter() {
  const { t } = useTranslation()
  const language = useLanguage()
  const keys = useKeys()
  const isLargeScreen = useScreenSize('lg')
  const experimentManager = useExperimentManager()
  const openLanguageDialog = useLanguageDialog()

  const footerSections = useMemo(() => {
    // Hide social section for medium screens in non-english languages because there's no room in footer
    const withSocialSection = language === LANGUAGES.ENGLISH || isLargeScreen
    return getMediumFooterLinks(
      withSocialSection,
      keys.ipCountry,
      experimentManager
    )
  }, [isLargeScreen])

  return (
    <S.Container>
      <S.MainContainer>
        <S.LinksContainer>
          {footerSections.map(({ label, links }) => (
            <S.SectionContainer key={label}>
              <S.SectionTitle>{t(label)}</S.SectionTitle>
              <S.LinksColumn>
                {links.map(
                  ({ label, href, target, onClick, extraProps = {} }) => (
                    <S.Link
                      key={label}
                      to={href || ''}
                      target={target || '_self'}
                      preventScrollReset={!href}
                      onClick={onClick}
                      {...extraProps}
                    >
                      {t(label)}
                    </S.Link>
                  )
                )}
              </S.LinksColumn>
            </S.SectionContainer>
          ))}
        </S.LinksContainer>
        <S.BottomContainer>
          <S.Language onClick={openLanguageDialog}>
            {t('general.languages.language')}:&nbsp;
            {t(`general.languages.${language.toLowerCase()}`)}
            <S.Arrow />
          </S.Language>
          <S.BottomSeparator />
          <S.BottomLinksContainer>
            {mediumBottomLinks.map(
              ({ label, onClick, href = '', extraProps = {} }) => (
                <S.BottomLink
                  key={label}
                  {...extraProps}
                  to={href}
                  onClick={onClick}
                >
                  {t(label)}
                </S.BottomLink>
              )
            )}
          </S.BottomLinksContainer>
        </S.BottomContainer>
      </S.MainContainer>
      <S.SideContainer>
        <ChatSection />
        <S.CopyRight>
          {t('pitch.copyright', { v1: new Date().getFullYear() })}
        </S.CopyRight>
      </S.SideContainer>
    </S.Container>
  )
}
