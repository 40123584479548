import * as S from './ChatSection.styles'
import { intercomService } from '../../../services/Analytics/platforms/intercom'
import { analytics, EVENT_NAMES } from '../../../services/Analytics/Analytics'
import { useTranslation } from 'react-i18next'

function ChatSection() {
  const { t } = useTranslation()

  return (
    <S.ChatContainer>
      <S.ChatTitle>{t('homepage.chat.title')}</S.ChatTitle>
      <S.ChatContent>{t('homepage.chat.content')}</S.ChatContent>
      <S.ChatButton
        onClick={() => {
          intercomService.show()
          analytics.track(EVENT_NAMES.TALK_TO_US_TAPPED, {
            Source: 'Homepage',
          })
        }}
      >
        {t('homepage.chat.cta')}
      </S.ChatButton>
    </S.ChatContainer>
  )
}

export default ChatSection
