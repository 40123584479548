import { isClient } from 'utils/runtimeUtils'
import {
  bottomLinks,
  type FooterLinksSection,
  links,
  socialLinks,
  TEXTS,
} from '../data'
import {
  DISCOVERY_URL,
  JOIN_US_URL,
  MIXTILES_ART_URL,
  PHOTO_BOOK_HOMEPAGE_URL,
  PHOTO_STYLER_URL,
  RECRUITING_COUNTRY,
} from '../../../config/config'
import { type ExperimentManager } from '../../../services/ExperimentManager/ExperimentManager'
import { shouldShowClassicToArt } from '../../ArtPitchSection/ArtPitchSection'

function getProductsSection(
  experimentManager: ExperimentManager
): FooterLinksSection {
  const productSection: FooterLinksSection = {
    label: TEXTS.PRODUCTS,
    links: [
      links.gift,
      links.business,
      { label: TEXTS.FRAME_YOUR_PHOTOS, href: PHOTO_STYLER_URL },
    ],
  }

  if (experimentManager.isEnabled('photo-books')) {
    productSection.links.push({
      label: TEXTS.PHOTO_BOOKS,
      href: PHOTO_BOOK_HOMEPAGE_URL,
    })
  }

  if (experimentManager.isEnabled('gallery-walls')) {
    productSection.links.push({
      label: TEXTS.GALLERY_WALLS,
      href: DISCOVERY_URL,
    })
  }

  if (
    isClient() &&
    shouldShowClassicToArt() &&
    !experimentManager.isEnabled('art-in-plus')
  ) {
    productSection.links.push({
      label: TEXTS.MIXTILES_ART,
      href: MIXTILES_ART_URL,
    })
  }
  return productSection
}

const commonResourcesLinks = [
  {
    label: TEXTS.COOKIES,
    onClick: () => {
      const anyWindow = window as any
      anyWindow.UC_UI.showSecondLayer()
    },
  },
  bottomLinks.accessibilityButton,
  bottomLinks.terms,
]

function getResourcesSection(country?: string): FooterLinksSection {
  const isRecruitingCountry = country?.toUpperCase() === RECRUITING_COUNTRY
  // For recruiting countries show the "Join Us" and put it first with "Careers"
  if (isRecruitingCountry) {
    return {
      label: TEXTS.RESOURCES,
      links: [
        links.careers,
        {
          label: TEXTS.JOIN_US,
          href: JOIN_US_URL,
        },
        ...commonResourcesLinks,
      ],
    }
  } else {
    return {
      label: TEXTS.RESOURCES,
      links: commonResourcesLinks,
    }
  }
}

export function getMediumFooterLinks(
  withSocialSection: boolean,
  country: string | undefined,
  experimentManager: ExperimentManager
): FooterLinksSection[] {
  const sections = [getProductsSection(experimentManager)]

  if (withSocialSection) {
    const socialSection = {
      label: TEXTS.FOLLOW_US,
      links: socialLinks,
    }
    sections.push(socialSection)
  }
  sections.push(getResourcesSection(country))
  return sections
}
