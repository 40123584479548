import useDialog from '../../elements/Dialog/DialogProvider'
import React, { useCallback } from 'react'
import LanguageSelectorDialog from '../LanguageSelector/LanguageSelectorDialog'
import { translateManager } from '../../services/TranslateManager'
import { useLanguage } from '../../services/LangProvider'

export default function useLanguageDialog() {
  const dialog = useDialog()
  const language = useLanguage()

  return useCallback(() => {
    dialog.open(
      <LanguageSelectorDialog
        open
        onClose={() => dialog.close()}
        onChangeLanguage={(lang: string) => {
          dialog.close()
          translateManager.setLanguage(lang)
        }}
        currentLanguage={language}
      />
    )
  }, [dialog, language])
}
