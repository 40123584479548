import type React from 'react'
import { styled, css } from 'styled-components'
import { Link as RouterdomLink, type LinkProps } from 'react-router-dom'
import {
  onLargeScreen,
  onMediumScreen,
  onXXLargeScreen,
} from 'mixtiles-web-common'
import { ReactComponent as ArrowFilled } from 'icons/assets/ArrowFilled.svg'
import { translateManager } from '../../../services/TranslateManager'
import { LANGUAGES } from '../../../locale/Translation.consts'

const RouterLink = RouterdomLink as unknown as React.ComponentClass<LinkProps>

export const Container = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.secondaryDark};
  padding: 60px 0;
  gap: 10px;
`

export const MainContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`

export const bottomLinksGap = css`
  ${onMediumScreen()} {
    gap: 10px;
  }

  ${onLargeScreen()} {
    gap: 20px;
  }
`

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  ${bottomLinksGap};

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: bottom left;
  }
`

export const BottomSeparator = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.grayMidDark};
`

export const SideContainer = styled.div`
  ${onMediumScreen()} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${onLargeScreen()} {
    margin-right: 70px;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  margin-bottom: 100px;

  ${onMediumScreen()} {
    gap: ${() =>
      translateManager.getLanguage() === LANGUAGES.FRENCH
        ? 'min(3vw, 70px)'
        : '70px'};
  }

  ${onLargeScreen()} {
    gap: 140px;
  }

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: top left;
  }
`

export const SectionContainer = styled.div`
  flex-shrink: 0;
`

export const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
`

export const LinksColumn = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  margin: 0;
  list-style: none;
  line-height: 42px;
`

export const Link = styled(RouterLink)`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.15px;
  flex-shrink: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondaryDark};
  &:active,
  &:hover {
    color: #000;
  }
`

export const BottomLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 14px;
  line-height: 18px;

  ${onMediumScreen()} {
    ${() =>
      translateManager.getLanguage() !== LANGUAGES.ENGLISH &&
      css`
        max-width: 15vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
  }

  ${onLargeScreen()} {
    max-width: unset;
  }
`

export const BottomLinksContainer = styled.div`
  display: flex;
  ${bottomLinksGap};
`

export const Language = styled('button')`
  color: ${({ theme }) => theme.colors.grayDark};
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

export const Arrow = styled(ArrowFilled)`
  width: 8px;
  height: 8px;
  margin-left: 5px;
`

export const CopyRight = styled.div`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: bottom right;
  }
`
