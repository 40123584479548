import * as S from './SmallScreenFooter.styles'
import { socials, links, bottomLinks } from '../data'
import React, { useMemo } from 'react'
import { useExperimentManager } from '../../../services/ExperimentManager/ExperimentManager'
import { BLOG_URL } from '../../../config/config'
import { analytics, EVENT_NAMES } from '../../../services/Analytics/Analytics'
import ChatSection from '../ChatSection/ChatSection'
import { useTranslation } from 'react-i18next'
import useLanguageDialog from '../useLanguageDialog'

export default function SmallScreenFooter() {
  const { t } = useTranslation()
  const experimentManager = useExperimentManager()
  const openLanguageDialog = useLanguageDialog()

  const footerLinks = useMemo(
    () =>
      experimentManager.isEnabled('blog')
        ? Object.values(links)
        : Object.values(links).filter(({ href }) => href !== BLOG_URL),
    []
  )

  return (
    <S.Container>
      <ChatSection />
      <S.Separator />

      <S.SocialsContainer>
        <S.SocialsTitle>{t('pitch.footer.follow_us')}</S.SocialsTitle>
        <S.SocialsList>
          {socials.map(({ Logo, href, alt, name }) => (
            <S.SocialsLink
              href={href}
              target="_blank"
              key={name}
              title={alt}
              onClick={() => {
                analytics.track(EVENT_NAMES.HOMEPAGE_FOLLOW_US_TAPPED, {
                  Source: 'Homepage',
                  Medium: name,
                })
              }}
            >
              <Logo />
            </S.SocialsLink>
          ))}
        </S.SocialsList>
      </S.SocialsContainer>
      <S.Separator />

      <S.LinksContainer>
        <li>
          <S.LanguageBtn onClick={openLanguageDialog}>
            {t('general.languages.language')}
          </S.LanguageBtn>
        </li>
        {footerLinks.map(
          ({ label, href, target = undefined, extraProps = {} }) => (
            <li key={label}>
              <S.Link
                to={href || ''}
                target={target || '_self'}
                preventScrollReset={!href || href.startsWith('#')}
                {...extraProps}
              >
                {t(label)}
              </S.Link>
            </li>
          )
        )}
        <li>
          <S.Link
            to=""
            onClick={() => {
              const anyWindow = window as any
              anyWindow.UC_UI.showSecondLayer()
            }}
            preventScrollReset
          >
            {t('homepage.cookies_choices')}
          </S.Link>
        </li>
      </S.LinksContainer>
      <S.Separator />

      <S.BottomContainer>
        <S.BottomLinksContainer>
          {Object.values(bottomLinks).map(
            ({ label, onClick, href = '', extraProps = {} }, index, array) => (
              <span key={label}>
                <S.BottomLink
                  {...extraProps}
                  to={href}
                  onClick={onClick}
                  preventScrollReset={!href}
                >
                  {t(label)}
                </S.BottomLink>
                {index % 2 === 0 && index !== array.length - 1 ? (
                  <>&nbsp;&nbsp;·&nbsp;&nbsp;</>
                ) : (
                  <br />
                )}
              </span>
            )
          )}
        </S.BottomLinksContainer>
        {t('pitch.copyright', { v1: new Date().getFullYear() })}
      </S.BottomContainer>
    </S.Container>
  )
}
