import { styled } from 'styled-components'
import { onMediumScreen, onXXLargeScreen } from 'mixtiles-web-common'

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;

  ${onMediumScreen()} {
    padding: 0;
    align-items: flex-start;
  }

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: top right;
  }
`

export const ChatTitle = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
`
export const ChatContent = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 250px;
`

export const ChatButton = styled.button`
  display: flex;
  height: 48px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  border-radius: 8px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.34px;
  transition: all 0.2s;

  &:hover&:not(:disabled) {
    background: ${({ theme }) => theme.colors.primaryAccent};
  }

  transition: transform 0.1s;
  &:active {
    transform: scale(0.97);
  }
`
