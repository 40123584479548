import type React from 'react'
import { styled } from 'styled-components'
import { Link as RouterdomLink, type LinkProps } from '@remix-run/react'

const RouterLink = RouterdomLink as unknown as React.ComponentClass<LinkProps>

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondaryDark};
  text-align: center;
  padding: 0 33px 66px;
`

export const Separator = styled.div`
  background: ${({ theme }) => theme.colors.grayAccent};
  height: 1px;
  width: 100%;
`

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 20px;
`
export const SocialsTitle = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`
export const SocialsList = styled.div`
  display: flex;
  gap: 12px;
`
export const SocialsLink = styled.a`
  transition: transform 0.1s;
  &:active {
    transform: scale(0.94);
  }
`

export const LinksContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 20px;
  margin: 0;
  list-style: none;
`

export const LanguageBtn = styled('button')`
  font-size: 15px;
  font-weight: 400;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondaryDark};
  &:active,
  &:hover {
    color: #000;
  }
`

export const Link = styled(RouterLink)`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondaryDark};
  &:active,
  &:hover {
    color: #000;
  }
`

export const BottomLink = styled(Link)`
  text-decoration: underline;
  font-size: inherit;
  font-weight: inherit;
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 30px 20px 30px;
  gap: 12px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.5;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.1;
`

export const BottomLinksContainer = styled.div``
